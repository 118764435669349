import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './config';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification, signInWithCustomToken, GoogleAuthProvider, signInWithPopup, updateProfile } from "firebase/auth";
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../components/common/toast';
import Gleap from "gleap";
import countryLanguage from 'country-language';
import {t} from '../components/common/translation';
import { fetchData } from '../hooks/apiUtils';
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null); // [name, email, uid
    const [hashedId, setHashedId] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const auth = getAuth();
    auth.useDeviceLanguage();
    const navigate = useNavigate();
    const path = useLocation().pathname;

    useEffect(() => {
      console.log("location changed");
      console.log(location);
      getStatus();
    }, [location]);
  
    const getStatus = async()=>{
        if(hashedId){
            const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
            setUserData(response);
        }
    }
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    async function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
        
    }

    function logout() {
        return auth.signOut();
    }

    function sendResetPasswordMail(email){
        return sendPasswordResetEmail(auth, email);
    }

    function sendVerification(user){
        return sendEmailVerification(user);
    }

    function signInWithToken(token){
        return signInWithCustomToken(auth, token);
    }

    function googleLogin(){
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }
    function updateProfileName(name){
        return updateProfile(auth.currentUser, {displayName: name})
    }
    const automateLogin = async(uid, redirect) => {
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
        
        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
            const token = data["customToken"];
            await signInWithToken(token);
            console.log("keke")
            if (redirect) {
                
                console.log(redirect)
                navigate("/dashboard");
                
            } else {
                navigate('/dashboard');
            }
            
        } else {
            console.log('Error:', response.status, response.statusText);
        }
        
        console.log("kek");
    };
    const findLanguage = async () => {
        try {
          const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
          const [iso639_1, countryCode] = browserLanguage.split("-");
      
          localStorage.setItem("dashboard-language", iso639_1);
          localStorage.setItem("dashboard-country", countryCode || 'US');
          localStorage.setItem("transcript-language", browserLanguage);
          window.location.reload();
      
        } catch (error) {
          console.error('Error detecting browser language:', error);
        }
      };
    useEffect(() => {
        // This is a listener which Firebase provides to determine 
        // authentication state. It's automatically managed, so if the token 
        // is invalidated or expired, the user will be logged out automatically.
        const unsubscribe = onAuthStateChanged((auth), user => {
            setCurrentUser(user);
            setLoading(false);
            console.log(user)
            if(user){
               if(user.emailVerified){
                    console.log("user is logged in")
                    fetch('https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid='
                    + user.uid + '&app=Transkriptor').then(response => response.json()).then(data => {
                        console.log(data)
                        const temp_hashedId = data.hashedId;
                        setHashedId(data.hashedId)
                        fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + data.hashedId).then(response => response.json()).then(data => {
                            setUserData(data)
                            const queryParams = new URLSearchParams(location.search);
                                // Get the user agent string
                                var userAgentString = navigator.userAgent;
                                if(!localStorage.getItem("transcript-language"))
                                    findLanguage();
                                // Detect the browser name
                                var browserName = "";
                                if(userAgentString.indexOf("Chrome") != -1){
                                    browserName = "Chrome";
                                }else if(userAgentString.indexOf("Firefox") != -1){
                                    browserName = "Firefox";
                                }else if(userAgentString.indexOf("Safari") != -1){
                                    browserName = "Safari";
                                }else if(userAgentString.indexOf("Opera") != -1 || userAgentString.indexOf("OPR") != -1){
                                    browserName = "Opera";
                                }else if(userAgentString.indexOf("Edge") != -1){
                                    browserName = "Edge";
                                }else if(userAgentString.indexOf("MSIE") != -1 || !!document.documentMode == true){
                                    browserName = "Internet Explorer";
                                }else{
                                    browserName = "Unknown browser";
                                }
                            Gleap.identify("User#" + temp_hashedId, {
                                email: data.Mail,
                                customData: {
                                subscription: data.SStatus,
                                segment: data.UserSegment,
                                language: localStorage.getItem('dashboard-language'),
                                browser: browserName
                                },
                            },
                                temp_hashedId
                            );
                            // To retrieve a specific query parameter:
                            const uid = queryParams.get('uid');
                            const redirect = queryParams.get('redirect');
                            if (redirect) {

                                   
                                    navigate("/dashboard");
                            } else {
                                if(path === "/signIn" || path === "/register" || path === "/" ){
                                    navigate('/dashboard');
                                } 
                            }
                          
                        })
   
                    })
                }else{
                    sendVerification(user);
                    showToast(t("Please check your mailbox to verify your email."), {type: "success", position:"top-center"});

                    logout();

                    if(path !== "/signIn" && path !== "/register" && !path.startsWith("/authentication")){
                        navigate('/signIn');
                        console.log("user is not logged in and path is not /signIn or /register")
    
                    }
                
                }
            }else{
                console.log("user is not logged in")
                const queryParams = new URLSearchParams(location.search);
                // To retrieve a specific query parameter:
                const uid = queryParams.get('uid');
                const redirect = queryParams.get('redirect');
                if(!redirect && path !== "/signIn" && path !== "/register" && !path.startsWith("/authentication")){
                    navigate('/signIn');
                    console.log("user is not logged in and path is not /signIn or /register")
                }else if(uid && redirect){
                    navigate("/dashboard");
                    automateLogin(uid, redirect);
                }
            }
        });

        // Cleanup subscription on unmount
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        hashedId,
        userData,
        getStatus,
        signup,
        login,
        logout,
        sendResetPasswordMail,
        sendVerification, 
        signInWithToken,
        googleLogin,
        updateProfileName
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
