// Payment.js
import React, {useEffect, useState} from 'react';

import offerSvg from '../assets/img/icon/offer.svg';
import logoTranskriptor from '../assets/img/icon/logo-transkriptor.png';
import logoSpeaktor from '../assets/img/icon/logo-speaktor.png';
import logoEskritor from '../assets/img/icon/logo-eskritor.png';
import { useAuth } from '../Auth/AuthContext';
import { showToast } from '../components/common/toast';
import speaktorQrCode from '../assets/img/speaktor_qr_code.png';

import { fetchData, fetchDataPost } from '../hooks/apiUtils';
import Gleap from 'gleap';
import playStorePng from '../assets/img/icon/playstore.png';
import appStorePng from '../assets/img/icon/appstore.png';
import qrCodePng from '../assets/img/icon/qr-code.png';
import promoCodePng from '../assets/img/upgrade/promocode.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from '../components/common/translation';
function Payment({handleOpenSidebar}) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // To retrieve a specific query parameter:
    const uid = queryParams.get('uid');
    const redirectProduct = queryParams.get('product');
    const {hashedId, signInWithToken} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [product, setProduct] = useState(null);
    const [active, setActive] = useState(true);
    const [businessActive, setBusinessActive] = useState(true);
    const [packageValue, setPackageValue] = useState("250");
    const [packagePrice, setPackagePrice] = useState("75");
    const [annualPrice, setAnnualPrice] = useState("900");
    const [businessPrice, setBusinessPrice] = useState(75);
    const [counterValue, setCounterValue] = useState(5);
    const navigate = useNavigate();
    const getUserSubscription = async()=>{
        const response = await fetchData("https://44iypa7tq6.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-ReturnPricingPage-v2?cid=" + hashedId);
        setStatus(response["mycase"]);
    };
    useEffect(()=>{
        getUserSubscription();
        setStatus(null);
        setProduct(null);

    },[hashedId]);
    useEffect(() => {
        const intervalId = setInterval(() => {
          let toggles = document.querySelectorAll(".btn-toggle");
      
          if(toggles.length > 0) { // if toggles are found
            for(let i = 0; i < toggles.length; i++) {
              toggles[i].style.setProperty("--before-content", '"' + t("Monthly") + '"');
              toggles[i].style.setProperty("--after-content", '"' + t("Annual") + '"');
            }
            clearInterval(intervalId); // Clear the interval once the effect has been applied
          }
        }, 100); // You can adjust the interval duration
      
        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, []);
      
    const redirectCheckout = async(e)=>{
        e.stopPropagation();
        if(status !== "Portal"){
            setIsLoading(true);
            console.log(e.currentTarget.getAttribute("data-value"))
            var product = e.currentTarget.getAttribute("data-value");
            var redirectUrl = "https://1s8r2nknq4.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-RedirectCheckoutPage-v2?cid=" + hashedId;
            if(product.includes("Business")){
                redirectUrl = redirectUrl + "&quantity=" + counterValue;
            }
            setProduct(product);
            redirectUrl = redirectUrl + "&product=" + product;
            const response = await fetchData(redirectUrl)
            const url = response["redirectUrl"];
            setIsLoading(false);
            window.open(url, "_blank");
        }else{
            setIsLoading(true);
            redirectCustomerPortal();
            setIsLoading(false);
        }

    } 
    const redirectCustomerPortal = async () => {
        const isChrome = window.navigator.userAgent.includes("Chrome")

        fetch("https://gf5c4w9xq7.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-CreateCustomerPortal-v2?cid=" + hashedId).then(res => res.json()).then(data => {
            const url = data["redirectUrl"];
            if(isChrome){
                window.open(url, "_blank");
            }else{
                window.location.href = url;
            }

        })

    }
    const changeOffers = async(e)=>{
        const isActive = e.currentTarget.classList.contains("active");
        if (isActive) {
            console.log("Button is active");
            // Handle active state
            setActive(true);

        } else {
            console.log("Button is not active");
            // Handle inactive state
            setActive(false);
        }
    };
    const changeOffersBusiness = async(e)=>{
        const isActive = e.currentTarget.classList.contains("active");
        if (isActive) {
            console.log("Button is active");
            // Handle active state
            setBusinessActive(true);
            setPackagePrice(packagePrice / 2);

        } else {
            console.log("Button is not active");
            // Handle inactive state
            setBusinessActive(false);
            setPackagePrice(packagePrice * 2);
            setAnnualPrice(packagePrice * 12);


        }
    };

    const handlePackageChange = async(e)=>{
        var value = e.target.value;
        var priceRate = businessActive ? 0.30 : 0.60;
        var price = value * priceRate;
        setPackageValue(value);

        if(value !== "specialOffer"){
            setPackagePrice(price);
            setAnnualPrice(price * 12);
        }else{

        }
        console.log(value);
    };
    const MIN_VALUE = 1;
    const MAX_VALUE = 20;
    
    function incrementCounter() {
        if (counterValue < MAX_VALUE) {
            const newValue = counterValue + 1;
            setCounterValue(newValue);
            setBusinessPrice(newValue * 15);
        }
    }
    
    function decrementCounter() {
        if (counterValue > MIN_VALUE) {
            const newValue = counterValue - 1;
            setCounterValue(newValue);
            setBusinessPrice(newValue * 15);
        }
    }
    
    function handleInputChange(e) {
        const value = parseInt(e.target.value, 10);
    
        if (value >= MIN_VALUE && value <= MAX_VALUE) {
            setCounterValue(value);
            setBusinessPrice(value * 15);
        }
    }
    
    useEffect(() => {
        if(redirectProduct){
            handleRedirect();
        }else if(!redirectProduct && uid){
            return;
            automateLogin(uid);
        }
        else{
            setLoading(false);
        }
    }, [uid]);
   
    const automateLogin = async(uid) => {
        setLoading(true);
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
        
        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
            const token = data["customToken"];
            await signInWithToken(token);
            setLoading(false);
            if(!redirectProduct)
                window.location.href = window.location.origin + window.location.pathname;
        } else {
            console.log('Error:', response.status, response.statusText);
        }
        
        console.log("kek");
    };
    const handleRedirect = async() =>{
        //attığımız maillerde hashed id alıyorsak bu şekilde yapalım yoksa mail ile de yapabilirim.
        let url = "https://4jjx377n73.execute-api.eu-central-1.amazonaws.com/default/redirectFlow?product=" + redirectProduct;
        if(uid){
          url = url + "&hashedId=" + uid;

        }else if(hashedId){
          url = url + "&hashedId=" + hashedId;
        }
        fetch(url).then((response) => response.json()).then(function (data) {
            console.log(data);
            console.log(data["redirectUrl"]);
            console.log("kek")
            if(uid)
                automateLogin(uid);
        
              setTimeout(function(){
                window.location.href = data["redirectUrl"];

              }, 3000);
        });
      }
    
    return (
        <>
        	{loading ? (
                <>
                <div className='row m-2'>
                    <div className='d-flex justify-content-center'>
                        <div className="spinner-border text-primary" role="status">

                        </div>
                    </div>
                 </div>
                </>
            ):(
                <>
           
            <div className='row justify-content-center '>
                <div className='col-12'>
                <div class="card card-padding card-margin text-center">
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='web-view'></div>
                        <span class="me-2 mobile-view" onClick={()=> handleOpenSidebar()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                      </span>
						<h3 class="mini-title">{t("For Individuals")}</h3>
                        <div></div>

                        </div>
						<div class="d-flex align-items-center justify-content-center">
							<div class="text-switch text-center my-4">
		                        <button type="button" class="btn btn-toggle active" data-bs-toggle="button" aria-pressed="true" autocomplete="off" onClick={(e)=>{
                                    changeOffers(e);
                                }}>
		                            <div class="handle"></div>
		                        </button>
		                    </div>
		                    <span class="offer-div"><span class="me-1"><img src={offerSvg} class="img-fluid" alt="img"/></span>{t("50% off")}</span>
						</div>
						<div class="d-flex align-items-center justify-content-center">
							<span class="info-icon theme-color">
								<svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.62" viewBox="0 0 16.376 16.62">
								  	<g id="info" transform="translate(-2.239 -2.244)"><path id="Path_124" data-name="Path 124" d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z" transform="translate(0 0)" fill="currentColor" fill-rule="evenodd"/></g>
								</svg>
							</span>
							<p class="text-start mb-0"><i>{t("Use your credits in")} <span class="medium blue-logo-text">Transkriptor</span>, <span class="medium pink-logo-text">Speaktor</span> {t("and")} <span class="medium yellow-logo-text">Eskritor</span> {t("with only one payment")}</i></p>
						</div>
					</div>
                </div>
                <div class="col-xxl-8 col-lg-10 col-12">
				
				</div>
                <div className='row m-2'style={{ display: isLoading ? "block" : "none" }}>
                    <div className='d-flex justify-content-center'>
                        <div className="spinner-border text-primary" role="status">

                        </div>
                    </div>
                 </div>
                <div class="w-100"></div>
                {active ? (
                    <>
                    <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin ">
                        <div class="card text-center card-100 payment-card"
                        data-value="Lite-Annual"
                        onClick={(e)=>{
                            redirectCheckout(e);
                        }}
                        >
                            <div class="theme-bg card-padding white-color">
                            <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ 4.99</h2>
                                <p class="ft-14-title mb-2 align-self-end">{t("/month")}</p>
                            </div>
                                <p class="mb-2 ft-14">{t("Annually Billed Amount: $59.95")}</p>
                            </div>
                            <div class="card-padding">
                                <h3 class="medium-title">{t("Lite")}</h3>
                                <p className='mb-0 ft-18 payment-bold'>{t("300 minutes of speech synthesis per month")}</p>
                                <p class="mb-0">{t("Usage reset every month, billed once per year")}</p>
                                <div className='row m-2'style={{ display: (isLoading && product=== "Lite-Annual") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                                </div>
                                <button class="btn theme-btn my-4" data-value="Lite-Annual"
                                onClick={(e)=>{

                                    redirectCheckout(e);
                                }}
                                >{t("Get started").toUpperCase()}
                                    <span class="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg>
                                    </span>
                                </button>
                                <div class="mt-1">
                                    <img src={logoSpeaktor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("300 minutes long speech synthesis")}</p>
                                    <img src={logoTranskriptor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("300 minutes long transcription")}</p>
                                    <img src={logoEskritor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-0">{t("300 AI generated content")}</p>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                        <div class="card text-center card-100  payment-card"
                        data-value="Premium-Annual"
                        onClick={(e)=>{
                      
                            redirectCheckout(e);
                        }}
                        >
                            <div class="theme-bg card-padding white-color">
                            <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ 12.99</h2>
                                <p class="ft-14-title mb-2 align-self-end">{t("/month")}</p>
                            </div>

                               
                                <p class="mb-2 ft-14">{t("Annually Billed Amount: $149.95")}</p>
                            </div>
                            <div class="card-padding">
                                <h3 class="medium-title">Premium</h3>
                                <p className='mb-0 ft-18 payment-bold'>{t("2400 minutes of speech synthesis per month")}</p>
                                <p class="mb-0">{t("Usage reset every month, billed once per year")}</p>
                                
                                <div className='row m-2'style={{ display: (isLoading && product=== "Premium-Annual") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                                </div>
                                <button class="btn theme-btn my-4"
                                data-value="Premium-Annual"
                                onClick={(e)=>{

                                    redirectCheckout(e);
                                }}
                                >{t("Get started").toUpperCase()}
                                    <span class="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg>
                                    </span>
                                </button>
                                <div class="mt-1">
                                    <img src={logoSpeaktor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("2400 minutes long speech synthesis")}</p>
                                    <img src={logoTranskriptor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("2400 minutes long transcription")}</p>
                                    <img src={logoEskritor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-0">{t("2400 AI generated content")}</p>
                                </div>
                            </div>
                        </div>
                </div>
                    </>
                ):(
                    <>
              <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                        <div class="card text-center card-100  payment-card"
                        data-value="Lite"
                        onClick={(e)=>{
                            redirectCheckout(e);
                        }}
                        >
                            <div class="theme-bg card-padding white-color">
                                <h2 class="package-price mt-2 mb-3">$ 9.99</h2>
                            </div>
                            <div class="card-padding">
                            <h3 class="medium-title">Lite</h3>
                                <p className='mb-0 ft-18 payment-bold'>{t("300 minutes of speech synthesis")}</p>
                                <p class="mb-0">{t("Available in 40+ languages")}</p>
                                <div className='row m-2'style={{ display: (isLoading && product=== "Lite") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                                </div>
                                <button class="btn theme-btn my-4" data-value="Lite"
                                onClick={(e)=>{

                                    redirectCheckout(e);
                                }}
                                >{t("Get started").toUpperCase()}
                                    <span class="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg>
                                    </span>
                                </button>
                                <div class="mt-1">
                                    <img src={logoSpeaktor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("300 minutes long speech synthesis")}</p>
                                    <img src={logoTranskriptor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("300 minutes long transcription")}</p>
                                    <img src={logoEskritor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-0">{t("300 AI generated content")}</p>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                        <div class="card text-center card-100  payment-card"
                        data-value="Premium"
                        onClick={(e)=>{
                      
                            redirectCheckout(e);
                        }}
                        >
                            <div class="theme-bg card-padding white-color">
                                <h2 class="package-price mt-2 mb-3">$ 24.99</h2>
                            </div>
                            <div class="card-padding">
                                <h3 class="medium-title">Premium</h3>
                                <p className='mb-0 ft-18 payment-bold'>{t("2400 minutes of speech synthesis")}</p>
                                <p class="mb-0">{t("Available in 40+ Languages")}</p>
                                <div className='row m-2'style={{ display: (isLoading && product=== "Premium") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                                </div>
                                <button class="btn theme-btn my-4"
                                data-value="Premium"
                                onClick={(e)=>{

                                    redirectCheckout(e);
                                }}
                                >{t("Get started").toUpperCase()}
                                    <span class="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                        </svg>
                                    </span>
                                </button>
                                <div class="mt-1">
                                    <img src={logoSpeaktor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("2400 minutes long speech synthesis")}</p>
                                    <img src={logoTranskriptor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-3">{t("2400 minutes long transcription")}</p>
                                    <img src={logoEskritor} class="img-fluid mb-2" alt="img"/>
                                    <p class="mb-0">{t("2400 AI generated content")}</p>
                                </div>
                            </div>
                        </div>
                </div>
                    </>
                )}
               <div class="w-100"></div>
                <div class="col-xxl-12 col-lg-10 col-12 sec-pad-top">
					<div class="card card-padding card-margin text-center ">
						<h3 class="mini-title">{t("For Teams")}</h3>
						<div class="d-flex align-items-center justify-content-center">
							<div class="text-switch text-center my-4">
                            <button type="button" class="btn btn-toggle active" data-bs-toggle="button" aria-pressed="true" autocomplete="off" onClick={(e)=>{
                                    changeOffersBusiness(e);
                                }}>
		                            <div class="handle"></div>
		                        </button>
		                    </div>
		                    <span class="offer-div"><span class="me-1"><img src={offerSvg} class="img-fluid" alt="img"/></span>{t("50% off")}</span>
						</div>
						<div class="d-flex align-items-center justify-content-center">
							<span class="info-icon theme-color">
								<svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.62" viewBox="0 0 16.376 16.62">
								  	<g id="info" transform="translate(-2.239 -2.244)"><path id="Path_124" data-name="Path 124" d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z" transform="translate(0 0)" fill="currentColor" fill-rule="evenodd"/></g>
								</svg>
							</span>
							<p class="text-start mb-0"><i>{t("Use your credits in")} <span class="medium blue-logo-text">Transkriptor</span>, <span class="medium pink-logo-text">Speaktor</span> {t("and")} <span class="medium yellow-logo-text">Eskritor</span> {t("with only one payment")}</i></p>
						</div>
					</div>
				</div>
                <div class="w-100"></div>

                {businessActive ? (
                    <>
                                 <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
					<div class="card text-center card-100 payment-card">
						<div class="theme-bg card-padding white-color"style={{ display: packageValue === "specialOffer" ? "none" : "" }}>
                            <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ {packagePrice}</h2>
                                <p class="ft-14-title mb-3 align-self-end">{t("/month")}</p>
                            </div>
							<p class="mb-2">{t("Annually Billed Amount:")} ${annualPrice}</p>
						</div>
                        <div class="theme-bg card-padding white-color"style={{ display: packageValue === "specialOffer" ? "" : "none" }}>
							<h2 class="package-price mt-2 mb-1">{t("Contact us")}</h2>

							<p class="mb-2">{t("Starts at $600 / month")}</p>
						</div>
						<div class="card-padding">
							<h3 class="medium-title mb-4">Premium+</h3>
							<p class="regular mb-2 dark-color">{t("Choose hours")}</p>
							<div class="form-group package-input mb-3">
								<select class="form-select form-control round-input border-input"
                                value={packageValue}
                                onChange={handlePackageChange}
                                >
                                    <option value="100">100 {t("hours/month")}</option>
                                    <option value="250">250 {t("hours/month")}</option>
									<option value="500">500 {t("hours/month")}</option>
									<option value="1000">1000 {t("hours/month")}</option>
									<option value="specialOffer">+1000 {t("hours/month")}</option>
								</select>
							</div>
							<p class="theme-color regular mb-4">{t("No more 5 minutes limit")}</p>
                            <div className='row m-2'style={{ display: (isLoading && product.includes("Hours-Annual")) ? "block" : "none" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className="spinner-border text-primary" role="status">

                                    </div>
                                </div>
                            </div>
                            <button class="btn theme-btn"
                            data-value={`${packageValue}-Hours-Annual`}
                            style={{ display: packageValue === "specialOffer" ? "none" : "" }}
                            onClick={(e)=>redirectCheckout(e)}
                            >{t("Get started").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
                            <button class="btn theme-btn"
                            style={{ display: packageValue === "specialOffer" ? "" : "none" }}
                            onClick={()=>Gleap.startFeedbackFlow('gvmfu')}
                            >{t("Contact us").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
					<div class="card text-center card-100 payment-card">
						<div class="theme-bg card-padding white-color">
                            <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ 15</h2>
                                <p class="ft-14-title mb-3 align-self-end">{t("/month/member")}</p>
                            </div>
							
							<p class="mb-2">{t("Annually Billed Amount:")} ${businessPrice * 12}</p>
						</div>
						<div class="card-padding">
							<h3 class="medium-title mb-4">{t("Business")}</h3>
							<p class="regular mb-2 dark-color">{t("Number of Members")}</p>
                            <div className="form-group counter-input mb-3">
                                <span className="count c-minus" onClick={decrementCounter}>-</span>
                                <input 
                                    type="text" 
                                    className="form-control round-input border-input" 
                                    name="" 
                                    placeholder=""
                                    value={counterValue}
                                    onChange={handleInputChange}
                                />
                                <span className="count c-plus" onClick={incrementCounter}>+</span>
                            </div>

							<p class="theme-color regular mb-4">{t("3000 minutes/month/member")}</p>
                            <div className='row m-2'style={{ display: (isLoading && product=== "Business-Annual") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                                </div>
							<button class="btn theme-btn"
                            data-value="Business-Annual"
                            onClick={(e)=>redirectCheckout(e)}
                            >{t("Get started").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>
                    </>
                ):(
                    <>
                              <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
					<div class="card text-center card-100 payment-card">
						<div class="theme-bg card-padding white-color"style={{ display: packageValue === "specialOffer" ? "none" : "" }}>
                            <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ {packagePrice}</h2>
                            </div>
						</div>
                        <div class="theme-bg card-padding white-color"style={{ display: packageValue === "specialOffer" ? "" : "none" }}>
							<h2 class="package-price mt-2 mb-1">{t("Contact us")}</h2>

							<p class="mb-2">{t("Starts at $600 / month")}</p>
						</div>
						<div class="card-padding">
							<h3 class="medium-title mb-4">Premium+</h3>
							<p class="regular mb-2 dark-color">{t("Choose hours")}</p>
							<div class="form-group package-input mb-3">
								<select class="form-select form-control round-input border-input"
                                value={packageValue}
                                onChange={handlePackageChange}
                                >
                                    <option value="100">100 {t("hours/month")}</option>
                                    <option value="250">250 {t("hours/month")}</option>
									<option value="500">500 {t("hours/month")}</option>
									<option value="1000">1000 {t("hours/month")}</option>
									<option value="specialOffer">+1000 {t("hours/month")}</option>
								</select>
							</div>
							<p class="theme-color regular mb-4">{t("No more 5 minutes limit")}</p>
                            <div className='row m-2'style={{ display: (isLoading && product.includes("Hours")) ? "block" : "none" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className="spinner-border text-primary" role="status">

                                    </div>
                                </div>
                            </div>
                            <button class="btn theme-btn"
                            data-value={`${packageValue}-Hours`}
                            style={{ display: packageValue === "specialOffer" ? "none" : "" }}
                            onClick={(e)=>redirectCheckout(e)}
                            >{t("Get started").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
                            <button class="btn theme-btn"
                            style={{ display: packageValue === "specialOffer" ? "" : "none" }}
                            onClick={()=>Gleap.startFeedbackFlow('gvmfu')}
                            >{t("Contact us").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>
				<div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
					<div class="card text-center card-100 payment-card">
                    <div class="theme-bg card-padding white-color">
                        <div className='d-flex justify-content-center'>
                                <h2 class="package-price mt-2 mb-1 ">$ 30</h2>
                                <p class="ft-14-title mb-3 align-self-end">{t("/member")}</p>
                            </div>
						</div>
						<div class="card-padding">
							<h3 class="medium-title mb-4">Business</h3>
							<p class="regular mb-2 dark-color">{t("Number of Members")}</p>
                            <div className="form-group counter-input mb-3">
                                <span className="count c-minus" onClick={decrementCounter}>-</span>
                                <input 
                                    type="text" 
                                    className="form-control round-input border-input" 
                                    name="" 
                                    placeholder=""
                                    value={counterValue}
                                    onChange={handleInputChange}
                                />
                                <span className="count c-plus" onClick={incrementCounter}>+</span>
                            </div>

							<p class="theme-color regular mb-4">{t("3000 minutes/month/member")}</p>
                            <div className='row m-2'style={{ display: (isLoading && product=== "Business") ? "block" : "none" }}>
                                    <div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-primary" role="status">

                                        </div>
                                    </div>
                            </div>
							<button class="btn theme-btn"
                            data-value="Business"
                            onClick={(e)=>redirectCheckout(e)}
                            >{t("Get started").toUpperCase()}
								<span class="ms-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
									  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>
                    </>
                )}
                				<div class="w-100"></div>
				<div class="col-12 sec-pad-top">
					<h3 class="medium-title text-center mb-lg-5 mb-4">{t("Frequently Asked Questions")}</h3>
					<div class="card card-padding card-margin">
						<div class="accordion accordion-flush dark-color" id="accordionFlushExample">
                            
                        <div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed bg-light jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCC" aria-expanded="false" aria-controls="collapseThree">{t("How can I solve payment error?")}</button>
                  </h2>
                  <div id="collapseCC" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                  <div class="accordion-body"><p class="jsontranslate">{t("There are multiple reasons for payment error. Firstly, please use VISA or Mastercard credit card. Debit cards or AMEX cause errors. Secondly, make sure you have sufficient balance and  your credit card is open for international online payments. If you still receive errors please download our mobile app and try your payment via Appstore or Playstore. Make sure to login with same mail address.")}</p> <br/>
                  <a href="https://play.google.com/store/apps/details?id=com.speaktor.app">
                                      <img width="304" height="83" src="https://transkriptor.com/wp-content/uploads/2021/05/Picture1.jpg" class="attachment-full size-full" alt="" loading="lazy" srcset="https://transkriptor.com/wp-content/uploads/2021/05/Picture1.jpg 304w, https://transkriptor.com/wp-content/uploads/2021/05/Picture1-300x83.jpg 300w" sizes="(max-width: 304px) 100vw, 304px" data-pagespeed-url-hash="4144987133"/>								</a>
                  <a href="https://apps.apple.com/us/app/text-reader-text-to-speech/id1632901543">
                                      <img width="302" height="86" src="https://transkriptor.com/wp-content/uploads/2021/05/Picture2.jpg" class="attachment-large size-large" alt="" loading="lazy" srcset="https://transkriptor.com/wp-content/uploads/2021/05/Picture2.jpg 302w, https://transkriptor.com/wp-content/uploads/2021/05/Picture2-300x86.jpg 300w" sizes="(max-width: 302px) 100vw, 302px" data-pagespeed-url-hash="144519758"/>								</a>
                  <img  width="200" height="200" src={speaktorQrCode}/>
                  </div>
                  </div>
                  </div>
                  
                  <div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed bg-light jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">{t("What payment methods do you accept? Is my payment secure?")}</button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body jsontranslate"><span class="jsontranslate">{t("We accept credit card from our website, but we also have mobile payment via Playstore (Android) or Appstore (IOS). Please download our mobile app to subscribe via Playstore or Appstore")}</span><br/>
                        <a href="https://play.google.com/store/apps/details?id=com.speaktor.app">
                                      <img width="304" height="83" src="https://transkriptor.com/wp-content/uploads/2021/05/Picture1.jpg" class="attachment-full size-full" alt="" loading="lazy" srcset="https://transkriptor.com/wp-content/uploads/2021/05/Picture1.jpg 304w, https://transkriptor.com/wp-content/uploads/2021/05/Picture1-300x83.jpg 300w" sizes="(max-width: 304px) 100vw, 304px" data-pagespeed-url-hash="4144987133" />								</a>
                  <a href="https://apps.apple.com/us/app/text-reader-text-to-speech/id1632901543">
                                      <img width="302" height="86" src="https://transkriptor.com/wp-content/uploads/2021/05/Picture2.jpg" class="attachment-large size-large" alt="" loading="lazy" srcset="https://transkriptor.com/wp-content/uploads/2021/05/Picture2.jpg 302w, https://transkriptor.com/wp-content/uploads/2021/05/Picture2-300x86.jpg 300w" sizes="(max-width: 302px) 100vw, 302px" data-pagespeed-url-hash="144519758"/>								</a>
                  <img  width="200" height="200" src={speaktorQrCode}/>
                  
                  </div>
                  </div>
                  </div>
                  
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingA">
                  <button class="accordion-button collapsed bg-light jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseA" aria-expanded="false" aria-controls="collapseA">{t("Will my subscription automatically renews? Can I cancel my subscription anytime?")}</button>
                  </h2>
                  <div id="collapseA" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body jsontranslate">{t("Yes, your subscription will renew automatically if you do not cancel your subscription. You can cancel your subscription anytime. To cancel your subscription go to My account section and click on Subscriptions, there you can cancel your subscription. It is as simple as that. If you were subscribed via Playstore or Appstore please manage your subscription via your Playstore or Appstore account.")}</div>
                  </div>
                  </div>
                  
                  <div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed bg-light jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapse41" aria-expanded="false" aria-controls="collapseThree">{t("Can I add more minutes if I use all my minutes before my subscription renews?")}</button>
                  </h2>
                  <div id="collapse41" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body jsontranslate">{t("Yes, to add more minutes you can purchase a subscription again. Please cancel your previous subscription by going to My subscriptions page to avoid double billing. Each customer is expected to have at most one active subscription at any time.")}</div>
                  </div>
                  </div>
                  <div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button bg-light collapsed jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">{t("When will my minutes expire?")}</button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body jsontranslate">{t("Unused minutes will expire after 1 month or 1 year depending on your subscription billing interval. Your subscription will be automatically renewed after your minutes expire.")}</div>
                  </div>
                  </div>
                  
                  <div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
                  <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button bg-light collapsed jsontranslate" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">{t("How can I redeem my coupon code?")}</button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body jsontranslate">{t("Please pick the subscription plan first, then write your coupon code and click on redeem button. Please check payment details section near the Subscribe button to see if your coupon is applied, then verify your credit card and complete the transaction")}</div>
                  </div>
                  </div>
						</div>
					</div>
				</div>
            </div>

                </>
            )}
        </>
    );
}

export default Payment;
