import React, {useEffect, useState} from 'react';

import audioIcon from '../assets/img/icon/audio-icon.svg';
import videoIcon from '../assets/img/icon/video-icon.svg';
import audioPng from "../assets/img/icon/audio-btn-icon.svg"
import readerPng from "../assets/img/icon/reader-btn-icon.svg"
import textReaderPng from "../assets/img/icon/text-reader.svg"
import googleDriveIcon from '../assets/img/icon/google-drive.svg';
import youtubeIcon from '../assets/img/icon/youtube.svg';
import googleMeet from '../assets/img/icon/google-meet.svg';
import zoom from '../assets/img/icon/zoom.svg';
import upgrade from '../assets/img/icon/upgrade.svg';
import folders from '../assets/img/icon/folders.svg';
import microsoftTeams from '../assets/img/icon/microsoft-teams.svg';
import recordPng from "../assets/img/icon/record-btn-icon.svg"
import { useAuth } from '../Auth/AuthContext';

import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import {t} from '../components/common/translation'
import { useModal } from '../components/common/Modal/ModalContext';
import LoadingModal from '../components/common/Modal/LoadingModal';
function Dashboard() {


  const [typedText, setTypedText] = useState("")
  const [loading, setLoading] = useState(false);
  
  const { hashedId } = useAuth();
  const navigate = useNavigate();
  const {showModal} = useModal();
  useEffect(() => {
    if(!localStorage.getItem("transcript-language"))
        findLanguage();
    if(!localStorage.getItem("isLanguageSet")){
        localStorage.setItem("isLanguageSet", true);
        showModal("LANGUAGE-MODAL");

    }
}, []);
const findLanguage = async () => {
  try {
    const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
    const [iso639_1, countryCode] = browserLanguage.split("-");

    localStorage.setItem("dashboard-language", iso639_1);
    localStorage.setItem("dashboard-country", countryCode || 'US');
    localStorage.setItem("transcript-language", browserLanguage);
    window.location.reload();

  } catch (error) {
    console.error('Error detecting browser language:', error);
  }
};

function lang_picker(raw_text){
  if( raw_text.length > 0){
    // document.getElementById('loading-type').style.display = "block";
    var currentDateTime = new Date();
    var resultInSeconds=currentDateTime.getTime() / 1000;
    var link = document.createElement('a');
    link.download = 'Typed text ' + Math.trunc(resultInSeconds)
    var blob = new Blob([raw_text], {type: 'text/plain'});
    link.href = window.URL.createObjectURL(blob);
    handleUpload(link.download, blob);
  } else{
    alert("Please don't leave it blank");

  }
  
}
function handleUpload(filename, file) {

  console.log(filename, file, hashedId)
    
  console.log("uploader_url")
  setLoading(true);
  //document.getElementById("input-form").style.display = "none";
  var API_ENDPOINT =
    "https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?";
  
  var INITIATEENDPOINT =
    "https://qtczjeif9l.execute-api.eu-central-1.amazonaws.com/default/AA-API-SpeaktorUploaderFinisher?";
  
  API_ENDPOINT = API_ENDPOINT + "cid=" + hashedId + "&filename=" + filename;
  const url = API_ENDPOINT;
  fetch(url)
    .then((response) => response.json())
    .then(function (data) {
    console.log(data["the_url"]);
    INITIATEENDPOINT =
      INITIATEENDPOINT + "cid=" + hashedId + "&filename=" + filename+ "&given_language=" + 'en-US' + "&given_voice_name=" + 'en-US-AmberNeural' + "&appVersion=2";
    (async () => {
      await uploadS3(
      data["the_url"],
      file,
      function (percent) {
        console.log(percent);
      }
      );
      fetch(INITIATEENDPOINT).then(response=>response.json()).then((data)=>{
      if( data.includes("Upload Limit")){
        // $('#upgrade-account-modal').modal('toggle');
      }else{


        setTimeout(function () {
          setLoading(false);
        
        console.log("Time out");
        navigate("/files")
        }, 1000);
      }
    
      });
    })().catch((e) => {
      console.error(e);
      setLoading(false);
    });
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
}
const uploadS3 = (url, file) => {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr);
        } else {
          reject(xhr);
        }
      }
    };
    xhr.open('PUT', url);
    xhr.send(file);
  });
};
  

    return (
       <>
          <LoadingModal show={loading} message={"Uploading"}/>
              <div className='row justify-content-center'>
                <div className='col-lg-12 card-margin'>
                  <div className='card card-padding card-100 text-center card-hover' onClick={()=>navigate("/uploader")}>
                    <div className='img-icon-list'>
                      <span className='pointer round-color-icon red-bg recorder-span'><img src={readerPng} class="img-fluid recorder-img"  alt="img"/></span>
                      {/* <span class="pointer round-color-icon green-bg recorder-span" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Audio")}><img src={audioPng} class="img-fluid recorder-img" alt="img"/></span> */}
                    </div>
                    <p class="ft-18-title">{t("Text Reader")}</p>
						        <p>{t("Convert Any Text Or Video File To Voice From Your Computer Or Web")}</p>
					
                  </div>
                </div>
                {/* <div className='col-lg-6 card-margin'>
                  <div className='card card-padding card-100 text-center card-hover'  onClick={()=> {    
                      fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
                        if(data.Minutes <= 0){
                          showModal("UPGRADE-MINUTES");
                          setTimeout(() => {
                            const url = 'http://app.speaktor.com/payment';
                            window.open(url, '_blank'); // '_blank' opens in a new tab or window
                          }, 3000)
                        }else{
                          showModal("TYPE-TEXT", {lang_picker: lang_picker})
                        }
                      })
                    }}>
                    <div class="img-icon-list">
                      <span className='pointer round-color-icon red-bg recorder-span'><img src={readerPng} class="img-fluid recorder-img"  alt="img"/></span>
                    </div>
                    <p class="ft-18-title">{t("Text Reader")}</p>
                    <p>{t("Copy Paste Or Write Any Text You Want And Convert This Text To Speech")}</p>
                  </div>
                </div> */}

                <div className='col-lg-8 card-margin'>
                  <div className='card card-padding card-100 '>
                    <Files isFile={false}/>
                  </div>
                </div>
                <div class="col-lg-4 card-margin d-lg-block d-none">
                  <div class="row h-100">
                    <div class="col-12 mb-3">
                      <div class="card card-padding card-100 flex-center card-hover" onClick={()=> navigate("/payment")}>
                        <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                          <img src={upgrade} class="img-fluid order-2 mb-sm-0 mb-3" alt="img"/>
                          <div class="me-3 order-1">
                            <p class="ft-18-title mb-2">{t("Upgrade")}</p>
                            <p class="mb-0">{t("Upgrade your account to transcribe long audio and video files")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="card card-padding card-100 flex-center card-hover"  onClick={()=> navigate("/folders")}>
                        <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                          <img src={folders} class="img-fluid order-2 mb-sm-0 mb-3" alt="img"/>
                          <div class="me-3 order-1">
                            <p class="ft-18-title mb-2">{t("Folders")}</p>
                            <p class="mb-0">{t("Create folders and arrange your files as your needs.")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                  </div>
				        </div>
           
              </div>
       </>
    );
}

export default Dashboard;



