import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    setTimeout(() => {
      window.location.reload();
    }, 300000);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Caught an error:", error, errorInfo);
    this.setState({ errorInfo });
  }

  
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='content-area p-0 text-center '>
          <h1>Something went wrong.</h1>
          {/* Uncomment the line below to display error information (for development) */}
          {/* <details>{this.state.errorInfo && this.state.errorInfo.toString()}</details> */}
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
