import React, { useState, useEffect, Suspense, useRef } from 'react';
import logo from './logo.svg';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { BrowserRouter, Routes, Route, Link, useNavigate, useLocation, Navigate, redirect } from 'react-router-dom';
import Files from './pages/Files/Files';
import Payment from './pages/Payment';
import Folders from './pages/Folders/Folders';
import Workspaces from './pages/Workspaces/Workspaces';
import Workspace from './pages/Workspace';
import Rewards from './pages/Rewards';
import Editor from './pages/Editor/Editor';
import Folder from './pages/Folder';
import Dashboard from './pages/Dashboard';
import LoginComponent from './pages/SignIn';
import SignUpComponent from './pages/SignUp';
import Account from './pages/Account';
import Recorder from './pages/Recorder/Recorder';
import Enterprise from './pages/Enterprise';
import MeetingAssistant from './pages/MeetingAssistant';
import Reader from './pages/Reader/reader';
import Sharing from './pages/Sharing/Editor';      
import ReceivedPayment from './pages/ReceivedPayment';
import Authentication from './pages/Authentication';
import ConnectionStatus from './components/common/ConnectionStatus';
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.css'
import '../src/assets/css/custome.css';
import '../src/assets/css/responsive.css';
import '../src/assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Gleap from "gleap";


import { AuthProvider, useAuth } from './Auth/AuthContext';
import { ModalProvider } from './components/common/Modal/ModalContext';
import BootstrapModal from './components/common/Modal/BootstrapModal';
import minutesPng from "./assets/img/icon/minute-btn-icon.svg"
import uploadPng from "./assets/img/icon/upload-btn-icon.svg"

import recordPng from "./assets/img/icon/record-btn-icon.svg"
import botPng from "./assets/img/icon/minute-btn-icon.svg"
import brandsPng from "./assets/img/icon/brands.png"
import languages from "./assets/img/icon/language.png"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Uploader from './pages/CreateAudio';
import Billing from './pages/Billing';
import ErrorBoundary from './components/common/errorBoundary';
import { loadLanguageTranslations } from './components/common/translation';
import { DirectionProvider, useDirection } from './components/common/DirectionContext';
import { showToast } from './components/common/toast';
import AudioPlayer from './pages/AudioPlayer';

// ... other imports ...


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function AppContent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const location = useLocation();

  const handleOpenSidebar = () => setIsOpen(true);
  const handleCloseSidebar = () => setIsOpen(false);
  const mainRef = useRef(null);
  const { currentUser, logout, userData } = useAuth();
  const { direction } = useDirection();
  /*const [shouldCheck, setShouldCheck] = useState(() => {
    // Get the shouldCheck state from sessionStorage
    const storedValue = sessionStorage.getItem('shouldCheck');
    return storedValue === null ? true : JSON.parse(storedValue);
  });
  


  const handleSlowConnection = () => {
    if (shouldCheck) {
      showToast("Your internet connection is slow. This might affect your experience.", "warning")

      // Set the shouldCheck state to false in sessionStorage to avoid rechecking after reload
      sessionStorage.setItem('shouldCheck', JSON.stringify(false));
      window.location.reload(); // refresh the page
    }else{
      showToast("Your internet connection is slow. This might affect your experience.", "warning")
    }
  };*/

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);
  const navigate = useNavigate();
  const checkRemember = async() => {
    const rememberSession = sessionStorage.getItem('remember');
    const rememberLocal = localStorage.getItem('remember');
    if (!rememberSession && rememberLocal === 'false') {
      await logout();
    }
  };
  const queryParams = new URLSearchParams(location.search);
  // To retrieve a specific query parameter:
  const redirect = queryParams.get('redirect');
  useEffect(() => {
    checkRemember();

    if (!redirect && !currentUser) {
          navigate('/signIn');
          console.log("currentUser is not logged in");

      } else {
          console.log("currentUser is logged in");
      }

  }, [currentUser, navigate]);

  if (!currentUser && !redirect) {
      return null;
  }
  Gleap.initialize("DiAhh3RrzRHlItmm6hxW70bBOKl73zWV");
  Gleap.setLanguage(localStorage.getItem('dashboard-language') || "en")

  const isEditorPage = location.pathname.startsWith("/reader") || location.pathname === "/payment" || location.pathname.startsWith("/sharing");

  

  return (
      <>
      


            <Sidebar isOpen={isOpen} handleCloseSidebar={handleCloseSidebar} isBlocked={isBlocked} />
                            <div className={`main-div ${isOpen ? "disable-layer": ""}`} >
                              {isEditorPage ? "" : <Header handleOpenSidebar={handleOpenSidebar}/>}

                         

                              <div className={isEditorPage ? 'content-area pb-0' : 'content-area'}>
                              <div className={isEditorPage ? 'min-100vh-relative-div' : 'container-fluid p-0'}>
                            <Routes>

                                  <Route path="/files" element={<Files isFile={true} handleOpenSidebar={handleOpenSidebar} />} />
                                  <Route path="/payment" element={<Payment handleOpenSidebar={handleOpenSidebar} />} />
                                  <Route path="/folders" element={<Folders />} />
                                  <Route path="/workspaces" element={<Workspaces />} />
                                  <Route path="/audio-player" element={<AudioPlayer />} />

                                  {/* <Route path="/reader/:tid?/:uid?/:orderid?" element={<Reader handleOpenSidebar={handleOpenSidebar}/>} /> */}
                                  <Route path="/reader" element={<Reader handleOpenSidebar={handleOpenSidebar}/>} />
                                  <Route path="/rewards" element={<Rewards/>} />
                                  <Route path="/folder/:fid/:uid" element={<Folder />} />
                                  <Route path="/workspace/:tid" element={<Workspace />} />
                                  <Route path="/editor/:tid?/:uid?" element={<Editor handleOpenSidebar={handleOpenSidebar} />} />
                                  <Route path="/sharing" element={<Editor handleOpenSidebar={handleOpenSidebar} />} />
                                  <Route path="/dashboard" element={<Dashboard />} />           
                                  <Route path="/uploader" element={<Uploader setIsBlocked={setIsBlocked} />} />           
                                  <Route path="/account" element={<Account />} />           
                                  <Route path="/billing" element={<Billing />} />  
                                  <Route path="/recorder" element={<Recorder setIsBlocked={setIsBlocked} />} />         
                                  <Route path="/enterprise" element={<Enterprise />} />
                                  <Route path="/meeting-assistant" element={<MeetingAssistant />} />
                                  <Route path="/payment-received" element={<ReceivedPayment />} />

                                  <Route path="*" element={<Navigate to="/dashboard" replace />} />

                         

                            </Routes>
                                  </div>
                              </div>

                            </div>
                           



      </>
  );
}

function App() {
  loadLanguageTranslations(localStorage.getItem("dashboard-language") || 'en');

  return (
    <ErrorBoundary>
      <DirectionProvider>

      <ModalProvider>

<BrowserRouter>

      <AuthProvider>

            <div className="App scale75">
            <BootstrapModal />

            <ToastContainer />

                    <Routes>
                        <Route path='/authentication' element={<Authentication />}/>
                        <Route path="/signIn" element={<LoginComponent />} />
                        <Route path="/register" element={<SignUpComponent />} />
                        <Route path="*" element={<AppContent />} />
                        
                    </Routes>
            </div>

        </AuthProvider>

      </BrowserRouter>
  </ModalProvider>
        </DirectionProvider>
          
    </ErrorBoundary>

  );
}

export default App;


