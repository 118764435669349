import React, { useEffect } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';
import { useState } from 'react';
import { useNavigate, Link, } from 'react-router-dom';
import { useModal } from '../components/common/Modal/ModalContext';
import filesPink from '../assets/img/icon/files-pink.svg';
import webLink from '../assets/img/icon/web-link.svg';
import dropbox from '../assets/img/icon/dropbox.svg';
import googleDrive from '../assets/img/icon/google-drive.svg';
import oneDrive from '../assets/img/icon/onedrive.svg';
import youtube from '../assets/img/icon/youtube.svg';
import uploadBox from '../assets/img/upload/upload-box.png';
import fileUrl from '../assets/img/upload/file-url.png';
import languagePng from '../assets/img/upload/language.png';
import servicePng from '../assets/img/upload/service.png';
import googleDriveFile from "../assets/img/upload/google-drive-file.png";
import { fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import {t} from '../components/common/translation'
import uploadPng from "../assets/img/icon/upload-btn-icon.svg"
import JSZip from 'jszip';
import LoadingModal from '../components/common/Modal/LoadingModal';

const multipartUrl =  'https://u8vqorw2rc.execute-api.eu-central-1.amazonaws.com/default/AA-API-multipartupload?';
var upload_arr = [];
var upload_status = [];
function Uploader({setIsBlocked}) {
    const { hashedId, userData } = useAuth();
    const fileInput = useRef();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState(null);
    const [service, setService] = useState(null);
	const [urlUpload, setUrlUpload] = useState(false);
	const [stillProcessing, setStillProcessing] = useState(false);
	const [urlStatus, setUrlStatus] = useState(null);
    const navigate = useNavigate();
    const { showModal } = useModal();
	const [progressFileName, setProgressFileName] = useState("");
  const [loading, setLoading] = useState(false);
	useEffect(() => {
		if(urlUpload || isLoading){
			setIsBlocked(true);
		}else if(!urlUpload && !isLoading){
			setIsBlocked(false);
		}
	}, [isLoading, urlUpload]);
	const [selectedFiles, setSelectedFiles] = useState(null);
	var urlIndex = 0;
	const [attachListener, setAttachListener] = useState(false);

	useEffect(() => {
	  const handleBeforeUnload = (event) => {
		event.preventDefault();
		event.returnValue = '';
	  };
  
	  if (attachListener) {
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }
	}, [attachListener]);
    const handleUpload = async (file, language, service, flag, files) => {
        console.log(file);
		if(flag && selectedFiles === 0){
			navigate('/files');
			return;
		}
        if (!file) return;
        setIsLoading(true);

		setProgressFileName(file.name);

		setAttachListener(true);
        let file_size = file.size /(1024 *1024);
        if(file_size > 5){
            multipartUpload(file, hashedId, navigate, setUploadProgress, language, service, flag, files);
            return;
        }else{
            // flow for files smaller than 5 mb
            const generalUploadUrl = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(file.name))}`;
                    
            try {
                // Step 1: Get the pre-signed URL from your server
                const response = await fetch(generalUploadUrl);
                const data = await response.json();
                const presignedUrl = data['the_url'];
                console.log('Received a presigned URL:', presignedUrl);

                // Step 2: Upload the file to S3
                const uploadResult = await uploadToS3(presignedUrl, file, (progress) => {
                    setUploadProgress(progress);
                    console.log(`Upload progress: ${progress}%`);
                });
                console.log('File uploaded successfully:', uploadResult);

                // Step 3: Notify your server about the successful upload
                const endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${hashedId}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(file.name))}&language=${language}&service=${service}`;
                const endpointResponse = await fetch(endpointUrl);
                const endpointData = await endpointResponse.json();
                console.log('Endpoint data:', endpointData);
				showToast(t("Uploaded File") + ": " + file.name, {type: "success"});
                // Navigate to the files route
				if(!flag){
					setAttachListener(false);

					navigate('/files');
				}else{
					const uploadedFileIndex = files.indexOf(file);

					// Get the next file if there is one
					const nextFile = uploadedFileIndex !== -1 && uploadedFileIndex < files.length - 1
					? files[uploadedFileIndex + 1]
					: null;

					if (nextFile) {
						setUploadProgress(0);
					console.log(`The next file to upload is: ${nextFile}`);
					onTranscribeFile(language, service, nextFile, flag, files);

					} else {
					console.log('No more files to upload.');
					setAttachListener(false);

					navigate('/files');
					}






				}
				

            } catch (err) {
                console.error('Error uploading file:', err);
            }
        }


      
    };
    
    const onTranscribeURL = async (language, service, url) => {
		if(userData.Minutes < 0){
			setTimeout(() => {
				showModal("UPGRADE-MINUTES");

			}, 1000);
			return;
		}
        console.log(url);
        console.log(language);
        console.log(service);
		if(!language){
			showToast(t("Please select a language"), {type: "error", position: "top-center"});
			return;
		}
		setUrlUpload(true);
		setUrlStatus("checking")
        fetchDataPost("https://3j3l2hnktb.execute-api.eu-central-1.amazonaws.com/default/Transcribe-From-Url-Initiation", 
        {"url": url, "user_id": hashedId, "service": service, "language": language}).then((data) => {
            if(data.status === 200){
                console.log(data);
				setUrlStatus("uploaded")
                showToast(t("Your file is being transcribed. You will be notified when it is ready.", {type: "success"}))
				setAttachListener(false)
                setTimeout(navigate('/files'), 3000)
            }else if(data.status === 500){
				setAttachListener(false)
				setUrlUpload(false);
				setUrlStatus("invalidUrl")
				
			}else if(data.status === 403){
				setAttachListener(false)
				setUrlUpload(false);

				setUrlStatus("privateUrl")
				

			}else if(data.status === 503){
				checkProcessing(url)
			}
        });



    };

	const checkProcessing = async (url) => {
		setStillProcessing(true);
		fetchDataPost("https://inwv3q0922.execute-api.eu-central-1.amazonaws.com/default/URL-Transcription-Check", {"url": url}).then((data) => {
			if(data === "processing" && urlIndex < 90){
				urlIndex++
				setTimeout(checkProcessing(url), 10000);

			}else if(data === "processing" && urlIndex >= 90){
				showToast(t("Upload failed, please try again..."), {type: "error"});
				setTimeout(navigate('/dashboard'), 3000);
			}else if(data === "ready"){
				console.log("in ready state waiting for us to upload")
				setAttachListener(false);
				setTimeout(function(){
					setUrlStatus("uploaded")
					navigate('/files')
				}, 20000);
			}
		})
	};

    const onTranscribeFile = async (language, service, file, flag, files) => {
		if(userData.Minutes < 0){
			showModal("UPGRADE-MINUTES");
			return;
		}
		if(!language){
			showToast(t("Please select a language"), {type: "error", position: "top-center"});
			return;
		}
        console.log(file);
		console.log(file.size /(1024 * 1024))
        console.log(language);
        console.log(service);
        setLanguage(language);
        setService(service);
		console.log("kek in transcribe file")
		if(!flag)
			flag = false;
        handleUpload(file, language, service, flag, files);
    };
  
	const onUploadMultipleFiles = async (files) => {
		showModal("TRANSCRIBE-MULTIPLE-FILES", {files: files}, {onTranscribeMultipleFiles});

	};

	const onTranscribeMultipleFiles = async (language, service, files) => {
		if(!userData){
			showToast(t("Unknown Error"), {type: "error"});
			window.location.reload();
		}
		if(userData.Minutes < 0){
			showModal("UPGRADE-MINUTES");
			return;
		}
		console.log(files);
		console.log(language);
		console.log(service);
		console.log("kek")

		onTranscribeFile(language, service, files[0], true, files);
		  
		
		  
	};

	const multipartUpload = async (file, hashedId, navigate, setUploadProgress, language, service, flag, files) => {
		let partSize = 5 * 1024 * 1024; // 5 MB
		let fileSize = file.size;
		let filename = file.name;
	
		if(fileSize > 1024 * 1024 * 1024 * 3)
		partSize = 20 * 1024 * 1024; // 10 MB
		else if(fileSize >  1024 * 1024 * 1024 ){
		partSize = 10 * 1024 * 1024; // 20 MB
		}
		const partCount = Math.ceil(fileSize / partSize);
		let multipartInitiateUrl = multipartUrl + `fileSize=${fileSize}&cid=${hashedId}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(filename))}&partCount=${partCount}&partSize=${partSize}&flag=true`;
	
		fetch(multipartInitiateUrl).then(response => response.json()).then(data => {
			const presignedUrls = data.presignedUrls;
			const uploadId = data.uploadId;
			console.log(presignedUrls);
			var partList = [];
			for (let i = 0; i < presignedUrls.length; i++) {
				const partNumber = presignedUrls[i].part_number;
				const presignedUrl = presignedUrls[i].presigned_url;
				const start = i * partSize;
				console.log(start)
			
				const end = Math.min((i + 1) * partSize, fileSize);
				const filePart = file.slice(start, end);
				upload_arr.push(0);
				let temp_arr = {
					url: presignedUrls[i],
					status: "started"
				}
				upload_status[i + 1] = temp_arr
						
				// Assuming 'presignedUrl' and 'fileChunk' are already defined
				partList.push({
					ETag: '',
					PartNumber: i + 1
				});
	
				let content_arr = {
					uploadId: uploadId,
					filename: filename,
					partCount: partCount,
					partList: partList,
					upload_arr:upload_arr,
					file_size: fileSize,
					language: language,
					service: service
				}
				upload_status[0] = content_arr;
				upload_part(presignedUrl, i, uploadId, partList, filename, multipartUrl, filePart, partCount,file, navigate, hashedId, setUploadProgress, language, service, flag, files)
			
			}
		})
	
	};
	const upload_part = async (
		presignedUrl, i, uploadId, partList, filename, 
		apiUrl, filePart, partCount, file, navigate, hashedId, setUploadProgress, language, service, multiFlag, files
	  ) => {
		try {
		  // Upload a part of the file using the presigned URL
		  let uploadCid = hashedId
		  const partResponse = await axios.put(presignedUrl, filePart, {
			headers: {
			  'Content-Type': 'application/octet-stream'
			},
			onUploadProgress: (e) => {
				console.log(e);
	
				upload_arr = upload_status[0].upload_arr;
				upload_arr[i] = e.loaded;
				const sum = upload_arr.reduce((acc, val) => acc + val, 0);
				upload_status[0].loaded = upload_arr;
				const percentComplete = (sum / upload_status[0].file_size) * 100;
				setUploadProgress(percentComplete);
				console.log(percentComplete);
			  
			}
		  });
	  
		  if (partResponse.status >= 200 && partResponse.status < 300) {
			console.log(filePart + " uploaded");
			const etag = partResponse.headers.etag;
	  
			if(partList.length > 0) {
			  partList[i].ETag = etag;
			}
	  
			upload_status[i + 1].status = "completed";
	  
			let flag = true;
			for (let j = 1; j < upload_status.length; j++) {
			  if (upload_status[j].status !== "completed") {
				flag = false;
				break;
			  }
			}
	  
			if (flag) {
			  // All parts have been uploaded, complete the multipart upload
			
	  
			
				try {
					const response = await axios.get(apiUrl, {
					params: {
						uploadId,
						filename: ((removeSpecialCharsAndTrim(filename))),
						cid: uploadCid
					}
					});
					// Handle the response here if needed.
				} catch (error) {
					console.error('Error during Axios request:', error);
					
					// Display a toast notification with the error message.
					showToast(t("Upload failed error: Your upload failed please try again. Please use Google Chrome, do not have any special characters (+,&,:...) in your file name", {type: "error"}))
					setAttachListener(false)	
				}
  
  
  
					// Step 3: Notify your server about the successful upload
					const endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${uploadCid}&filename=${encodeURIComponent(removeSpecialCharsAndTrim(file.name))}&language=${language}&service=${service}`;
					const endpointResponse = await fetch(endpointUrl);
					const endpointData = await endpointResponse.json();
					console.log('Endpoint data:', endpointData);
					showToast(t("Uploaded File") + ": " + file.name, {type: "success"});

					// Navigate to the files route
					if(!multiFlag){
						setAttachListener(false);

						navigate('/files');
					}
					else{
						const uploadedFileIndex = files.indexOf(file);
	
						// Get the next file if there is one
						const nextFile = uploadedFileIndex !== -1 && uploadedFileIndex < files.length - 1
						? files[uploadedFileIndex + 1]
						: null;
	
						if (nextFile) {
							setUploadProgress(0);
						console.log(`The next file to upload is: ${nextFile}`);
						onTranscribeFile(language, service, nextFile, multiFlag, files);
	
						} else {
						console.log('No more files to upload.');
						setAttachListener(false);

					navigate('/files');
						}
	
	
	
	
	
	
					}
				
	  
			  // More logic here...
	  
			}
		  } else {
			console.error(`Failed to upload part ${i + 1}: ${partResponse.status}`);
			// Your retry logic and snackbar call goes here
		  }
	  
		} catch (error) {
		  console.error("Error during upload:", error);
		  // Your error logic goes here
		}
	  }

	  const removeSpecialCharsAndTrim = (filename) => {
		// Remove special characters (#, &, *) and trim whitespace
		let temp = filename.replace(/[&#*]/g, '').trim();
		return temp;
	  };
	  

		async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response; // parses JSON response into native JavaScript objects
    }
	

		function uploadByWebLink(url){
			setLoading(true)
			if( url.length > 0){
				postData("https://n43h3aufwi.execute-api.eu-central-1.amazonaws.com/default/Text-Scraping-Speaktor",
				{'GivenWebsite': url}).then(
					(response) =>{
					return response.json().then( (data) =>{
						var currentDateTime = new Date();
						var resultInSeconds=currentDateTime.getTime() / 1000;
						var link = document.createElement('a');
						link.download = "Converted Url " +  Math.trunc(resultInSeconds)
						var blob = new Blob([data], {type: 'text/plain'});
						link.href = window.URL.createObjectURL(blob);
						handleUploadSpeaktor(link.download, blob);
					})
					}
				)
				}else{
					alert("Please don't leave it blank");
				}
			
		
			}
		
		const onUploadFile = async (file) => {
			setLoading(true)
			if (file.name.endsWith(".txt")) {
				const reader = new FileReader();
				reader.onload = (e) => {
					console.log(2, e.target.result);
					showToast(t("Uploaded File") + ": " + file.name, {type: "success"});
					handleUploadSpeaktor(file.name, e.target.result)
						// setFileContent(e.target.result);
				};
				reader.readAsText(file, "UTF-8");
				return;
			}
			else if (file.name.endsWith(".docx")) {
				const arrayBuffer = await file.arrayBuffer();
				const zip = new JSZip();
				const contents = await zip.loadAsync(arrayBuffer);
				const documentXmlFile = contents.files['word/document.xml'];
				if (!documentXmlFile) {
						console.error('The specified .docx file does not contain word/document.xml.');
						return;
				}
				const documentXmlContent = await documentXmlFile.async('text');
				const parser = new DOMParser();
	
				const documentXml = parser.parseFromString(documentXmlContent, 'text/xml');
	
				const paragraphs = Array.from(documentXml.getElementsByTagName('w:p'));
				let textArray = [];
	
				for (let p of paragraphs) {
						let textInParagraph = "";
						const textElements = Array.from(p.getElementsByTagName('w:t'));
						for (let t of textElements) {
							console.log(1, t.textContent)
								textInParagraph += t.textContent;
						}
						console.log(textInParagraph, "textInParagraph")
	
						// Detect <w:br/> elements for line breaks within a paragraph
						const breaks = Array.from(p.getElementsByTagName('w:br'));
						if (breaks.length > 0) {
								textArray.push(textInParagraph);
								for (let i = 0; i < breaks.length; i++) {
										textArray.push("");  // To represent a line break
								}
						} else {
								textArray.push(textInParagraph);
						}
				}
				handleUploadSpeaktor(file.name, textArray.join('<br>'))
				return;
			}
			else if (file.name.endsWith(".doc")) {
				handleUploadSpeaktor(file.name, file)
		}
			else{
				showToast(t("It might take a few minutes to extract content") + ": " + file.name, {type: "success"});
				const headers = {
						'Content-Type': 'application/json',
				};
				console.log(userData, "userdata")
				const cust_mail = userData["Mail"];  // Assuming `user` is globally available or passed as prop
	
				// setGenerating(true);
				// setLoadingMessageByLanguage(t("Uploading:") + file.name)
				const response = await fetch(`https://qo3b5po9z4.execute-api.eu-central-1.amazonaws.com/default/AA-APIGetCustomerHashedKey?mail=${cust_mail}`, {
					method: 'GET',
					headers: headers
				});
				const data = await response.json();
				console.log(data)
	
				const fileExtension = file.name.split('.').pop();
	
				// Assuming FirebaseMessaging is equivalent to some service that gets token in React
				// const device_token = await someServiceToGetToken(); 
	
				const url_2_get_s3_link = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&service=&language=&filename=${file.name}`;
	
				const resp2 = await fetch(url_2_get_s3_link);
				const resp2Data = await resp2.json();
	
				const formData = new FormData();
				formData.append('file', file);
	
				console.log(resp2Data['the_url'], "resp2Data['the_url']")
	
				// const resp9 = await fetch(resp2Data['the_url'], {
				//     method: 'PUT',
				//     body: formData,  // directly sending the file
				//     headers: {
				//       'Content-Type': file.type  // assuming 'file' is an instance of File
				//     }
				// });
	
				console.log(resp2Data['the_url'], file, "resp2Data['the_url']")
				uploadS3(resp2Data['the_url'], file, (progress) => {
					console.log(progress)
				}).catch((error) => {
					return
				})
	
				// console.log(resp9, "resp9")
				// if (!resp9.ok) {
				//     // Handle error, use toast or any other notification system
				//     return;
				// }
				setTimeout(function () {
					setLoading(false)
					navigate('/files')
				}, 5000);
	
				const up_finisher_url = `https://qtczjeif9l.execute-api.eu-central-1.amazonaws.com/default/AA-API-SpeaktorUploaderFinisher?` +  "cid=" + hashedId + "&filename=" + file.name+ "&given_language=" + 'en-US' + "&given_voice_name=" + 'en-US-AmberNeural' + "&appVersion=2";
	
				handleUploadSpeaktor(file.name, file)
				// await fetch(up_finisher_url, {
				// 	method: 'GET',
				// 	headers: headers
				// }).then((response) => response.json()).then((data) => {
				// 	console.log("TAMAM")
				// });
				// let orderid = JSON.parse(resp_up_finisher.body).orderid;
				// console.log(resp_up_finisher.body)
				// const respFinisherData = await resp_up_finisher.json();
				// console.log(respFinisherData['orderid'])
				// setOrderID(respFinisherData['orderid']);
				// setOrderInfo({orderID: respFinisherData['orderid'], title: file.name, content: '', tstatus: "Processing"});
				// setTStatus("Processing");
				// setGenerating(false);
				// handleUploadSpeaktor(file.name, textArray.join('<br>'))
				// handleCreateDocument(null, 'We are currently extracting content of your file. It might take a few minutes. Please check back later.', file.name)
			}
		};
	function lang_picker(raw_text){
		setLoading(true)
		if( raw_text.length > 0){
			// document.getElementById('loading-type').style.display = "block";
			var currentDateTime = new Date();
			var resultInSeconds=currentDateTime.getTime() / 1000;
			var link = document.createElement('a');
			link.download = 'Typed text ' + Math.trunc(resultInSeconds)
			var blob = new Blob([raw_text], {type: 'text/plain'});
			link.href = window.URL.createObjectURL(blob);
			handleUploadSpeaktor(link.download, blob);
		} else{
			alert("Please don't leave it blank");

		}
		
	}
	function handleUploadSpeaktor(filename, file) {
		setLoading(true)
		console.log(filename, file, hashedId)
		console.log("uploader_url")
		//document.getElementById("input-form").style.display = "none";
		var API_ENDPOINT =
			"https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?";
		
		var INITIATEENDPOINT =
			"https://qtczjeif9l.execute-api.eu-central-1.amazonaws.com/default/AA-API-SpeaktorUploaderFinisher?";
		
		API_ENDPOINT = API_ENDPOINT + "cid=" + hashedId + "&filename=" + filename;
		const url = API_ENDPOINT;
		fetch(url)
			.then((response) => response.json())
			.then(function (data) {
			console.log(data["the_url"]);
			INITIATEENDPOINT =
				INITIATEENDPOINT + "cid=" + hashedId + "&filename=" + filename+ "&given_language=" + 'en-US' + "&given_voice_name=" + 'en-US-AmberNeural' + "&appVersion=2";
			(async () => {
				await uploadS3(
				data["the_url"],
				file,
				function (percent) {
					console.log(percent);
				}
				);
				fetch(INITIATEENDPOINT).then(response=>response.json()).then((data)=>{
				if( data.includes("Upload Limit")){
					// $('#upgrade-account-modal').modal('toggle');
				}else{


					setTimeout(function () {
						setLoading(false)
					
					console.log("Time out");
					navigate('/files')
					}, 5000);
				}
			
				});
			})().catch((e) => {
				console.error(e);
			});
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const uploadS3 = (url, file) => {
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest();

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(xhr);
					} else {
						reject(xhr);
					}
				}
			};
			xhr.open('PUT', url);
			xhr.send(file);
		});
	};
  
  
    return (
       <>
  
					<LoadingModal show={loading} message={`${t("Uploading")}`} />
            <div className='row justify-content-center' style={{display: userData ? "": "none"}}>
            <div className="col-12 card-margin"style={{ display: !isLoading ? "none" : "" }}>
					<div className="card card-padding card-100 text-center">
						<div className="progress mb-4">
						  	<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                style={{ width: `${uploadProgress}%` }} 
                                aria-valuenow={uploadProgress} 
                                aria-valuemin="0" 
                                aria-valuemax="100"
                            ></div>
						</div>
						<strong>{uploadProgress.toPrecision(3)}%</strong>
						<p className="ft-18-title mb-1">{t("Please Wait")}</p>
						<p>{t("Please wait until your file is uploaded to cloud. Your file will be transcribed...")}</p>
						<p>{progressFileName}</p>
					</div>
			</div>
			<div className="col-12 card-margin"style={{ display: !urlUpload ? "none" : "" }}>
					<div className="card card-padding card-100 text-center">
						<div class="spinner-border text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<p className="ft-18-title mb-1">{t("Please Wait")}</p>
						<p style={{display: stillProcessing ? "" : "none"}} >{t("It might take some time. Please wait until your URL is transcribed...")}</p>
						<p>{t("Please wait until your url is uploaded to cloud. your URL will be transcribed...")}</p>
					</div>
			</div>
			<div className="col-12 card-margin" style={{display: urlStatus=== "invalidUrl" || urlStatus === "privateUrl" ? "": "none"}}>
					<div className="card card-padding card-100 text-center">

						{urlStatus === "invalidUrl" ? (
							<>
								<strong >{t("Please make sure that the url is valid, share file link from Youtube, Google Drive, Dropbox, OneDrive or raw media files...")}</strong>
								<div class="img-icon-list">
									<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Dropbox"><img src={dropbox} class="img-fluid" alt="img"/></span>
									<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Drive"><img src={googleDrive} class="img-fluid" alt="img"/></span>
									<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="OneDrive"><img src={oneDrive} class="img-fluid" alt="img"/></span>
									<span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={youtube} class="img-fluid" alt="img"/></span>
								</div>
							</>
						): null}
						{urlStatus === "privateUrl" ? (
							<>
								<div>
									<strong>{t("Please make sure that the file in url is public. To change your files privacy settings, please check links below...")}</strong>
									<ul style={{listStyleType: "none", paddingLeft: "0"}}>
										<li>
											<strong>Youtube</strong>: <a href='https://support.google.com/youtube/answer/157177' target='_blank'>https://support.google.com/youtube</a>
										</li>
										<li>
											<strong>Google Drive</strong>: <a href='https://support.google.com/a/users/answer/9308873' target='_blank'>https://support.google.com/google-drive</a>
										</li>
										<li>
											<strong>Dropbox</strong>: <a href='https://www.dropbox.com/features/share/file-permissions#:~:text=Yes%2C%20you%20can%20make%20Dropbox,to%20edit%20the%20original%20file.' target='_blank'>https://www.dropbox.com/permissions</a>
										</li>
										<li>
											<strong>OneDrive</strong>: <a href='https://support.microsoft.com/office/share-files-and-folders-in-onedrive-personal-3fcefa26-1371-401e-8c04-589de81ed5eb' target='_blank'>https://support.microsoft.com/en-us/office/onedrive/permissions</a>
										</li>
									</ul>
								</div>
								</>
						): null}
					</div>
			</div>
				<div class="col-lg-4 card-margin" style={{ display: isLoading || urlUpload ? "none" : "" }}>
					<div class="card card-padding card-100 flex-center text-center card-hover" onClick={() => {
						setUrlStatus("checking")
						if(userData.Minutes <= 0){
							showModal("UPGRADE-MINUTES");
							setTimeout(() => {
								const url = 'http://app.speaktor.com/payment';
								window.open(url, '_blank'); // '_blank' opens in a new tab or window
							}, 3000)
						}else{
							showModal("UPLOAD-FILE", {onUploadFile: onUploadFile})
						}
						// fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
						// 	if(data.Minutes <= 0){
						// 		showModal("UPGRADE-MINUTES");
						// 		setTimeout(() => {
						// 			const url = 'http://app.speaktor.com/payment';
						// 			window.open(url, '_blank'); // '_blank' opens in a new tab or window
						// 		}, 3000)
						// 	}else{
						// 		showModal("UPLOAD-FILE", {onUploadFile: onUploadFile})
						// 	}
						// })
						// showModal('UPLOAD-FILE',{ onUploadFile })
					}}>
						<div class="img-icon-list">
							<span class="theme-color">
								<svg xmlns="http://www.w3.org/2000/svg" width="75.848" height="61.913" viewBox="0 0 75.848 61.913">
								  <g id="noun-drag-417775" transform="translate(-12.423 -19.52)">
								    <path id="Path_96" data-name="Path 96" d="M86.432,42.766a6.281,6.281,0,0,0-7.6-.983l-.006.008h0a6.278,6.278,0,0,0-5.792-3.854,6.132,6.132,0,0,0-3.529,1.1,6.42,6.42,0,0,0-.969-1.266,6.289,6.289,0,0,0-7.108-1.235v-8.7a6.313,6.313,0,0,0-1.839-4.442,6.241,6.241,0,0,0-4.441-1.824,6.246,6.246,0,0,0-6.273,6.261V44.065a6.712,6.712,0,0,0-4.982.4,4.542,4.542,0,0,0-2.371,3.161,19.414,19.414,0,0,0-.172,3.692c0,3-.02,6.658-.015,6.664a23.46,23.46,0,0,0,46.921-.011L88.271,47.2A6.28,6.28,0,0,0,86.432,42.766ZM64.8,77.815A19.866,19.866,0,0,1,44.951,57.981s.021-6.046.021-8.959a1.383,1.383,0,0,1,.673-1.394,3.883,3.883,0,0,1,3.224.255l.009,7.415a1.8,1.8,0,0,0,1.8,1.8,1.807,1.807,0,0,0,1.811-1.811V27.828a2.656,2.656,0,0,1,2.66-2.66,2.657,2.657,0,0,1,2.666,2.651V50.842a1.808,1.808,0,1,0,3.616-.008V42.206a2.666,2.666,0,1,1,5.332,0l0,1.979c-.008.008,0,.015-.009.022l.007.007v6.619a1.808,1.808,0,1,0,3.615.007V44.207a2.662,2.662,0,1,1,5.324-.008l0,6.631a1.806,1.806,0,0,0,1.811,1.811,1.825,1.825,0,0,0,1.811-1.813L79.322,47.2a2.689,2.689,0,0,1,.785-1.888,2.649,2.649,0,0,1,1.881-.778A2.744,2.744,0,0,1,84.654,47.2V57.963A19.9,19.9,0,0,1,64.8,77.815ZM25.966,31.574h3.623V26.715h2.344L27.778,19.52l-4.155,7.195h2.344v4.859Zm3.623,6.6H25.966v4.859H23.622l4.155,7.195,4.155-7.195H29.588V38.176Zm-5.113-5.113H19.618V30.72l-7.195,4.155,7.195,4.155V36.686h4.858Zm18.657,1.812L35.938,30.72v2.344H31.08v3.623h4.858v2.344Z" fill="currentColor"/>
								  </g>
								</svg>
							</span>
						</div>
						<p class="ft-18-title">{t("Drop File")}</p>
						<p>{t("Drop a File (PDF, Word, Txt, etc...) Here or Browse Files")}</p>
					</div>
				</div>
				<div class="col-lg-4 card-margin" style={{ display: isLoading || urlUpload ? "none" : "" }}>
					<div class="card card-padding card-100 flex-center text-center card-hover"onClick={() => {
						setUrlStatus("checking")
						if(userData.Minutes <= 0){
							showModal("UPGRADE-MINUTES");
							setTimeout(() => {
								const url = 'http://app.speaktor.com/payment';
								window.open(url, '_blank'); // '_blank' opens in a new tab or window
							}, 3000)
						}else{
							showModal("TYPE-TEXT", {lang_picker: lang_picker})
						}
						// fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
						// 	console.log(userData)
						// 	if(data.Minutes <= 0){
						// 		showModal("UPGRADE-MINUTES");
						// 		setTimeout(() => {
						// 			const url = 'http://app.speaktor.com/payment';
						// 			window.open(url, '_blank'); // '_blank' opens in a new tab or window
						// 		}, 3000)
						// 	}else{
						// 		showModal("TYPE-TEXT", {lang_picker: lang_picker})
						// 	}
						// })
						// showModal('TYPE-TEXT', {lang_picker})
					}}>
						<div class="img-icon-list">
							<span class="theme-color">
								<img src={filesPink} class="img-fluid" alt="img"/>
							</span>
						</div>
						<p class="ft-18-title">{t("Type or Paste")}</p>

						<p>{t("Type or paste anything and convert in to the audio")}</p>
					</div>
				</div>
				<div class="col-lg-4 card-margin" style={{ display: isLoading || urlUpload ? "none" : "" }}>
					<div class="card card-padding card-100 flex-center text-center card-hover"onClick={() => {
						setUrlStatus("checking")
						if(userData.Minutes <= 0){
							showModal("UPGRADE-MINUTES");
							setTimeout(() => {
								const url = 'http://app.speaktor.com/payment';
								window.open(url, '_blank'); // '_blank' opens in a new tab or window
							}, 3000)
						}else{
							showModal("WEB-LINK", {uploadByWebLink: uploadByWebLink})
						}
						// fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
						// 	if(data.Minutes >= 0){
						// 		showModal("UPGRADE-MINUTES");
						// 		setTimeout(() => {
						// 			const url = 'http://app.speaktor.com/payment';
						// 			window.open(url, '_blank'); // '_blank' opens in a new tab or window
						// 		}, 3000)
						// 	}else{
						// 		showModal("WEB-LINK", {uploadByWebLink: uploadByWebLink})
						// 	}
						// })
						// showModal('WEB-LINK',{ uploadByWebLink })
					}}>
						<div class="img-icon-list">
							<span class="theme-color">
								<img src={webLink} class="img-fluid" alt="img"/>
							</span>
						</div>
						<p class="ft-18-title">{t("Paste a Web Link")}</p>

						<p>{t("Fetch The Text From A Website And Create Audio.")}</p>
					</div>
				</div>
				<div class="col-12 sec-pad-top">
					<h3 class="medium-title text-center mb-lg-5 mb-4">{t("Frequently Asked Questions")}</h3>
					<div class="card card-padding card-margin">
						<div class="accordion accordion-flush dark-color" id="accordionFlushExample">
							<div class="accordion-item">
								<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingOne">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
						        		{t("Is there a file size limit, my file is too large how can I upload it faster?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
						    		<div class="accordion-body">
						      			<p>{t("There is 5 gb file size limit, but uploading long video files may take too much time, we suggest to convert your video file to mp3 or wav to upload your files much faster. You can also upload your files from Youtube or your Cloud Storage (Google Drive, One Drive or Dropbox). To upload from Cloud or Youtube, you only need to share a public link of your file or video, so that you don't have to wait to upload.")}</p>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingTwo">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
						        		{t("Is there a recording duration limit?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<p>{t("Yes, Trial accounts can transcribe the first 5 minutes of their audio files whereas paid accounts can transcribe up to 6 hour length recordings but recordings longer than 3 hours are divided into two parts")}</p>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingThree">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
						        		{t("What is the difference between Standard, Subtitle and Speaker Seperated?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<ul>
						      				<li>
						      					<p class="medium">{t("Standard:")}</p>
						      					<p>{t("The most popular transcription solution, it has high accuracy and consistent results for any transcription task, great for meetings and self recordings.")}</p>
						      				</li>
						      				<li>
						      					<p class="medium">{t("Subtitle:")}</p>
						      					<p>{t("A custom solution to transcribe subtitles. It provides shorter chunks of text and provides SRT format.(You need to be paid member to download in SRT format)")}</p>
						      				</li>
						      				<li>
						      					<p class="medium">{t("Speaker Seperated (Beta):")}</p>
						      					<p>{t("Our custom model separates transcription results by people. If you need to know who said what, you could try this solution. Note that results are better with lower number of speakers and speakers with different voice tones e.g. male and female. If the speakers have very similar voices the result may be worse than Standard transcription.")}</p>
						      				</li>
						      			</ul>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingFour">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
						        		{t("Which file formats do you support?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<p>{t("We support several audio and video formats. Supported file formats are MP3, MP4, WAV, AAC, M4A, WEBM, FLAC, OPUS, AVI, M4V, MPEG, MOV, OGV, MPG, WMV, OGM, OGG, AU, WMA, AIFF, OGA.")}</p>
						      			<p>{t("If you have different format please use any media converter tool to convert your file to supported format. Please ensure that you keep high audio quality.")}</p>
						      			<p>{t("Here are several online media conversion tools you may use:")}</p>
						      			<ul>
						      				<li>
						      					<p class="mb-2"><a target="_blank" href="https://convertio.co" class="theme-color underline">https://convertio.co</a></p>
						      				</li>
						      				<li>
						      					<p class="mb-2"><a target="_blank" href="https://cloudconvert.com" class="theme-color underline">https://cloudconvert.com</a></p>
						      				</li>
						      				<li>
						      					<p class="mb-2"><a target="_blank" href="https://online-audio-converter.com" class="theme-color underline">https://online-audio-converter.com</a></p>
						      				</li>
						      			</ul>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingFive">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
						        		{t("How can I convert Youtube videos to text?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<p class="medium">{t("Upload via link:")}</p>
						      			<ul>
						      				<li>
						      					<p>{t("Click 'Convert Audio' button")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={uploadBox} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Paste the url of Youtube video")}<br></br>{t("Please make sure that the link or file is public")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={fileUrl} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Select the language spoken in the video or audio recording.")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={languagePng} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Select service")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={servicePng} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      			</ul>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingSix">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
						        		{t("How can I convert my audio or video files in my Google Drive, OneDrive, or Dropbox accounts to text?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<p class="medium">{t("Upload via link:")}</p>
						      			<ul>
						      				<li>
						      					<p>{t("Click 'Convert Audio' button")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={uploadBox} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Paste the url of Youtube video")}<br></br>P{t("lease make sure that the link or file is publi")}c</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={fileUrl} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Select the language spoken in the video or audio recording.")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={languagePng} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      				<li>
						      					<p>{t("Select service")}</p>
						      					<div class="img-shadow-box mb-3">
						      						<img src={servicePng} class="img-fluid" alt="img"/>
						      					</div>
						      				</li>
						      			</ul>
						      			<p class="medium">{t("How to make a public Google Drive file?")}</p>
						      			<ul>
						      				<li>
						      					<p>{t("Go to Google Drive, open the file, and click the share button in the upper right.")}</p>
						      				</li>
						      				<li>
						      					<p>{t("Click General Access and then choose 'Anyone with link' from the dropdown menu")}</p>
						      				</li>
						      			</ul>
				      					<div class="img-shadow-box mb-3">
				      						<img src={googleDriveFile} class="img-fluid" alt="img"/>
				      					</div>
						    		</div>
						    	</div>
						  	</div>
						  	<div class="accordion-item">
						  		<span class="collapse-icon fa fa-angle-double-right"></span>
						    	<h2 class="accordion-header" id="flush-headingSeven">
						      		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
						        		{t("How can I improve transcription quality?")}
						      		</button>
						    	</h2>
						    	<div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
						      		<div class="accordion-body">
						      			<p class="medium">{t("There are different reasons that are affect transcription quality:")}</p>
						      			<p class="medium">{t("Heavy background noise")}</p>
						      			<p>{t("This is by far the biggest enemy of high-quality transcription. As far as possible, try indoor or in-studio recording, as noise editing is quite an arduous task. If you have background noise, firstly eliminate it.")}</p>
						      			<p>{t("Platforms like Audacity, Adobe Audition, and AVID have tools to help, but it's not a sure fix. Audacity is a free open-source audio recording and editing application.")} </p>
						      			<ul>
						      				<li><p>{t("Open Audacityteam.org/download, click “File” and “Open” and locate the file you want to fix")}</p></li>
						      				<li><p>{t("Select your “Room Tone” by dragging your mouse over a section where there is little or no audio sound")}</p></li>
						      				<li><p>{t("Select the “Effect” menu and then select “Noise Reduction”")}</p></li>
						      				<li><p>{t("Click “Get Noise Profile” and wait for the modal to disappear")}</p></li>
						      				<li><p>{t("Click back to your timeline of audio to clear your selection")}</p></li>
						      				<li><p>{t("Select the “Effect” menu again and select “Noise Reduction” again")}</p></li>
						      				<li><p>{t("Click “Preview” to hear the default noise removal settings. You can select one of these defaults to see if they work")}</p></li>
						      				<li><p>{t("If you still hear noise, then simply adjust the Sliders (see descriptions below) and then click “Preview” again")}</p></li>
						      			</ul>
						      			<p>{t("Keep the microphone close to the speaker")}</p>
						      			<p>{t("The closer the microphone is to the speaker, the better. Enunciating the words well will ensure better sound fidelity.")}</p>
						      			<p class="medium">{t("Skype and phone interviews")}</p>
						      			<p>{t("These typically have very low recording quality due to the compression used in these services. You may encounter lower accuracy with these recordings.")}</p>
						      			<p class="medium">{t("Heavy accents")}</p>
						      			<p>{t("Our models are trained in a lot of data, but heavy accents are one of the most difficult things to transcribe!")}</p>
						    		</div>
						    	</div>
						  	</div>
						</div>
					</div>
				</div>
            </div>

       </>
    );
}

export default Uploader;


// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
    try {
        const result = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgress(percentCompleted);
            }
        });
        return result;
    } catch (error) {
        throw error;
    }
};


