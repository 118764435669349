import React from 'react';
import { useApiData } from '../hooks/useApiData';
import { useParams } from 'react-router-dom';
import Files from './Files/Files';
function Folder() {
    const { fid, uid } = useParams();


    return (
        <>
       <Files fid={fid} uid={uid} isFile={true} isFolder={true} />

        </>
    );
}

export default Folder;
