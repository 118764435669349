function LoadingModal({ show, message = 'Please wait',  }) {
  if (!show) return null;
  
  return (
    <div className="modal-overlay2">
      <div class="loading-modal-content2">
          <div class="loading-loader2"></div>
          <h3 class="loading-text2">{message === undefined ? "Loading" : message}...</h3>
      </div>
    </div>
  );
}

export default LoadingModal;