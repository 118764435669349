import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import minutesPng from "../../assets/img/icon/minute-btn-icon.svg"

import audioPng from "../../assets/img/icon/audio-btn-icon.svg"
import readerPng from "../../assets/img/icon/reader-btn-icon.svg"

import brandsPng from "../../assets/img/services.png"
import languages from "../../assets/img/icon/language.png"
import logoTranskriptor from '../../assets/img/logo-transkriptor-nobuffer.png';
import logoSpeaktor from '../../assets/img/logo_nobuffer.png';
import logoEskritor from '../../assets/img/logo-eskritor-nobuffer.png';
import logoAdmin from '../../assets/img/logo-admin.png';
import { useApiData } from "../../hooks/useApiData";
import { useModal } from '../common/Modal/ModalContext';
import { loadLanguageTranslations } from '../common/translation';
import {t} from '../common/translation';
import LoadingModal from '../common/Modal/LoadingModal';
function Header({ handleOpenSidebar }) {
    const location = useLocation();
    const { hashedId, userData } = useAuth();
    const [minutes, setMinutes] = useState(null);
    const {showModal} = useModal();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (userData) {
            setMinutes(userData.Minutes);
        }
    }, [userData]);
    useEffect(() => {
        console.log("xke", location.pathname);
        getMinutes();
    }, [location.pathname]);
    const getMinutes = async () => {
        fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
            setMinutes(data.Minutes);
        })
    };
    let text;
    switch (location.pathname) {
        case '/uploader':
            text = 'Upload File';
            break;
        case '/recorder':
            text = 'Record Audio';
            break;
        case '/files':
            text = 'Files';
            break;
        case '/folders':
            text = 'Folders';
            break;
        // Add more cases as needed
        default:
            text = 'Home'; // default text if no path matches
            break;
    }
    const openLangDialog = (event) => {
        if (event) event.preventDefault();
        
        showModal("LANGUAGE-MODAL");
        
        const modalContent = document.body.querySelector(".modal-content");
        if (modalContent) {
            modalContent.classList.add("overflow-visible");
        } else {
            console.error("No element with class .modal-content found!");
        }
    }

    const handleNavigate = (brand) => {
        // Get the current path
        var currentPath = location.pathname;
        if(currentPath === "/dashboard") {
            currentPath = "/uploader"
        }
        // Construct new URL
        const newURL = `https://app.${brand}.com${currentPath}`;
        // Redirect to the new URL
        window.open(newURL, "_blank");
    };

    useEffect(() => {
        const languageCode = localStorage.getItem("dashboard-language") || 'en';
        loadLanguageTranslations(languageCode); // Load translations once when the app starts
    
    }, []);

    function lang_picker(raw_text){
        if( raw_text.length > 0){
          // document.getElementById('loading-type').style.display = "block";
          var currentDateTime = new Date();
          var resultInSeconds=currentDateTime.getTime() / 1000;
          var link = document.createElement('a');
          link.download = 'Typed text ' + Math.trunc(resultInSeconds)
          var blob = new Blob([raw_text], {type: 'text/plain'});
          link.href = window.URL.createObjectURL(blob);
          handleUpload(link.download, blob);
        } else{
          alert("Please don't leave it blank");
      
        }
        
      }
      function handleUpload(filename, file) {
      
        console.log(filename, file, hashedId)
          
        console.log("uploader_url")
        setLoading(true);
        //document.getElementById("input-form").style.display = "none";
        var API_ENDPOINT =
          "https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?";
        
        var INITIATEENDPOINT =
          "https://qtczjeif9l.execute-api.eu-central-1.amazonaws.com/default/AA-API-SpeaktorUploaderFinisher?";
        
        API_ENDPOINT = API_ENDPOINT + "cid=" + hashedId + "&filename=" + filename;
        const url = API_ENDPOINT;
        fetch(url)
          .then((response) => response.json())
          .then(function (data) {
          console.log(data["the_url"]);
          INITIATEENDPOINT =
            INITIATEENDPOINT + "cid=" + hashedId + "&filename=" + filename+ "&given_language=" + 'en-US' + "&given_voice_name=" + 'en-US-AmberNeural' + "&appVersion=2";
          (async () => {
            await uploadS3(
            data["the_url"],
            file,
            function (percent) {
              console.log(percent);
            }
            );
            fetch(INITIATEENDPOINT).then(response=>response.json()).then((data)=>{
            if( data.includes("Upload Limit")){
              // $('#upgrade-account-modal').modal('toggle');
            }else{
      
      
              setTimeout(function () {
                setLoading(false);
              
              console.log("Time out");
              navigate("/files")
              }, 1000);
            }
          
            });
          })().catch((e) => {
            console.error(e);
            setLoading(false);
          });
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });
      }
      const uploadS3 = (url, file) => {
        return new Promise(function (resolve, reject) {
          const xhr = new XMLHttpRequest();
      
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(xhr);
              } else {
                reject(xhr);
              }
            }
          };
          xhr.open('PUT', url);
          xhr.send(file);
        });
      };

    return (
       <>
       <LoadingModal show={loading} message={t("Loading")} />
        <div className="header-div">
            <div className="header-div-flex">
                <div className="d-flex align-items-center">
                    <div className='d-xl-none d-flex align-items-center me-3 pointer' id='sidebar-menu-open'onClick={handleOpenSidebar}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <Link >
                    <img src={logoAdmin} class="img-fluid header-logo" alt="img"/>
                    </Link>
                    </div>
                    <div className='d-xl-flex d-none'>
                        <Link to="payment" className='header-minute-text semibold'>
                            <span className="round-color-icon theme-bg">
                                <img src={minutesPng} className='img-fluid' alt='img'></img>
                            </span> {minutes} {t("minutes")}
                        </Link>
                        <Link to="uploader" className='btn header-btn rounded-btn'>
                            <span className="round-color-icon red-bg">
                                <img src={readerPng} className='img-fluid' alt='img'></img>
                            </span> {t("Text Reader")}
                            {/* <span className="round-color-icon green-bg ">
                                <img src={audioPng} className='img-fluid' alt='img'></img>
                            </span> {t("Create Audio")} */}
                        </Link>
                        {/* <Link  onClick={()=> 
                            fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
                              if(data.Minutes <= 0){
                                showModal("UPGRADE-MINUTES");
                                setTimeout(() => {
                                  const url = 'http://app.speaktor.com/payment';
                                  window.open(url, '_blank'); // '_blank' opens in a new tab or window
                                }, 3000)
                              }else{
                                showModal("TYPE-TEXT", {lang_picker: lang_picker})
                              }
                            })  
                          } className='btn header-btn rounded-btn'>
                            <span className="round-color-icon red-bg">
                                <img src={readerPng} className='img-fluid' alt='img'></img>
                            </span> {t("Text Reader")}
                        </Link> */}
                    </div>
                   
                </div>
                <div className='d-flex align-items-center'>
                        <div className='pointer' onClick={()=> openLangDialog()} >
                            <img src={languages} width="45px" class="img-fluid res-header-side-icon me-3" alt="img">
                            </img>
                        </div>
                        <div className='dropdown'>
                            <Link className='btn dropdown-toggle no-toggle-icon' role='button'>
                            <img src={brandsPng} width="45px" class="img-fluid res-header-side-icon" alt="img"/>

                            </Link>
                            <ul className='dropdown-menu dropdown-menu-end'>
                                <li><Link className='dropdown-item active' onClick={()=> handleNavigate('transkriptor')}>
                                <img src={logoTranskriptor} class="img-fluid brand-drop-logo" alt="img">
                                </img>              
                                    
                                    </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" to="">
                                        <img src={logoSpeaktor}class="img-fluid brand-drop-logo" alt="img"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" onClick={()=> handleNavigate('eskritor')}>
                                        <img src={logoEskritor} class="img-fluid brand-drop-logo" alt="img"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
            </div>

        </div>      
             <div className='res-header-dash-sticky d-xl-none d-flex'>
             <Link to="payment" className='header-minute-text semibold'>
                            <span className="round-color-icon theme-bg">
                                <img src={minutesPng} className='img-fluid' alt='img'></img>
                            </span> {minutes} {t("minutes")}
            </Link>
             <Link to="uploader" className='btn header-btn rounded-btn'>
         {/* <span className="round-color-icon green-bg">
             <img src={audioPng} className='img-fluid' alt='img'></img>
         </span>  */}
             <span className="round-color-icon red-bg">
                 <img src={readerPng} className='img-fluid' alt='img'></img>
             </span> 
         </Link>
         {/* <Link  onClick={()=> 
                            fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
                              if(data.Minutes <= 0){
                                showModal("UPGRADE-MINUTES");
                                setTimeout(() => {
                                  const url = 'http://app.speaktor.com/payment';
                                  window.open(url, '_blank'); // '_blank' opens in a new tab or window
                                }, 3000)
                              }else{
                                showModal("TYPE-TEXT", {lang_picker: lang_picker})
                              }
                            })  
                          } className='btn header-btn rounded-btn'>
             <span className="round-color-icon red-bg">
                 <img src={readerPng} className='img-fluid' alt='img'></img>
             </span> 
         </Link> */}
           </div>  
       </>
    );
}

export default Header;
