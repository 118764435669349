// AudioPlayer.js
import React, { useEffect } from 'react';

const AudioPlayer = () => {
  useEffect(() => {
    const audio = new Audio('https://speaktor.s3.eu-central-1.amazonaws.com/11fb90419cc470d9786b847626f252229af2d20d32c8ce44a0fd5a95eb16175f7e8356e4bde80fdf720a1edd4ec5a147f2bfd88c820dbbba2266d0616f9ece30en-US-JennyNeural.mp3');
    audio.play().catch(error => {
      // Handle error if audio doesn't play
      console.error("Playback failed:", error);
    });
  }, []);

  return <div>Your content here...</div>;
};

export default AudioPlayer;
